import type {WpPage} from "~/integration/wordpress/types";
import type {MetaInfo} from "@intlify/core-base";

export default () => {
    const getSeo = (page: WpPage) => {
        const seo = {
            meta: [] as MetaInfo[],
            title: 'nuxt-app',
            script: []
        }

        if (page?.seo?.title) {
            seo.title = page.seo.title;
            seo.meta.push({
                hid: 'title',
                name: 'title',
                content: page.seo.title
            } as MetaInfo)
        }

        if(page?.seo?.metaDesc) {
            seo.meta.push({
                hid: 'description',
                name: 'description',
                content: page.seo.metaDesc
            } as MetaInfo)
        }

        if(page?.seo?.schema?.raw) {
            const schema = page.seo.schema.raw;
            seo.script.push({
                hid: 'schema',
                type:'application/ld+json',
                innerHTML: schema
            });
        }

        return seo;
    }

    return {
        getSeo
    };
}